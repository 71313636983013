.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.wrapper {
  width: 100%;
  max-width: 500px;
  background: #161616;
  padding: 3em 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  color: #fff;
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
}

.qrContainer {
  padding: 2em 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr {
  min-width: 18em;
  width: 100%;
  border-radius: 14px;
}

.text {
  color: #fff;
  font-size: 1.1em;
  text-align: center;
  padding-top: 2em;
  font-weight: 600;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0 0 0;
}

.buttons > a {
  margin: 0 1em;
  cursor: pointer;
}

.storeIcon {
  height: 3em;
}

.deeplinkContainer {
  padding: 5em 0;
}

.deeplink {
  cursor: pointer;
  border: 1px solid #2665ff;
  background: #2665ff;
  color: #fff;
  border-radius: 5px;
  padding: 0 2em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deeplink > span {
  font-weight: 500;
  font-size: 1.1em;
}
